import "./src/blue-library/styles/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import smoothscroll from 'smoothscroll-polyfill';



export const shouldUpdateScroll = () => {
  // eslint-disable-next-line no-undef
  const isAnchor = window.location.href.includes("#");
  if (!isAnchor) {
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  }
  return false
}

export const onClientEntry = () => {
  //  VWO SmartCode
  const linkElement = document.createElement("link");
  linkElement.rel = "preconnect";
  linkElement.href = "https://dev.visualwebsiteoptimizer.com";
  const vwoInnerHtml =`window._vwo_code || (function() {
  var account_id=968825,
  version=2.1,
  settings_tolerance=2000,
  hide_element='body',
  hide_element_style = 'opacity:0 !important;filter:alpha(opacity=0)!important;background:none !important;transition:none !important;',
  /* DO NOT EDIT BELOW THIS LINE */
  f=false,w=window,d=document,v=d.querySelector('#vwoCode'),cK='_vwo_'+account_id
  +'_settings',cc={};try{var
  c=JSON.parse(localStorage.getItem('_vwo_'+account_id+'_config'));cc=c&&typeof
  c==='object'?c:{}}catch(e){}var
  stT=cc.stT==='session'?w.sessionStorage:w.localStorage;code={nonce:v&&v.nonce,use_existing_jquery:function(){return typeof use_existing_jquery!=='undefined'?use_existing_jquery:undefined},
  library_tolerance:function(){return typeoflibrary_tolerance!=='undefined'?library_tolerance:undefined},
  settings_tolerance:function(){return cc.sT||settings_tolerance},hide_element_style:function(){return'{'+(cc.hES||hide_element_style)+'}'},
  hide_element:function(){if(performance.getEntriesByName('first-contentful-paint')[0]){return''}return typeof cc.hE==='string'?cc.hE:hide_element},
  getVersion:function(){return version},finish:function(e){if(!f){f=true;
  var t=d.getElementById('_vis_opt_path_hides');if(t)t.parentNode.removeChild(t);
  if(e)(new Image).src='https://dev.visualwebsiteoptimizer.com/ee.gif?a='+account_id+e}},finished:function(){return f},addScript:function(e){
  var t=d.createElement('script');t.type='text/javascript';if(e.src){t.src=e.src}else
  {t.text=e.text}v&&t.setAttribute('nonce',v.nonce);d.getElementsByTagName('head')[0].appendChild(t)},
  load:function(e,t){ var n=this.getSettings(),i=d.createElement('script'),r=this;
  t=t||{};if(n){i.textContent=n;d.getElementsByTagName('head')[0].appendChild(i);if(!w.VWO||VWO.caE){stT.removeItem(cK);r.load(e)}}else{var o=new
  XMLHttpRequest;o.open('GET',e,true);o.withCredentials=!t.dSC;o.responseType=t.responseType||'text';o.onload=function(){if(t.onloadCb){return
  t.onloadCb(o,e)}if(o.status===200||o.status===304){_vwo_code.addScript({text:o.responseText})}else{_vwo_code.finish('&e=loading_failure:'+e)}};
  o.onerror=function(){if(t.onerrorCb){returnt.onerrorCb(e)}_vwo_code.finish('&e=loading_failure:'+e)};o.send()}},getSettings:function(){try{var
  e=stT.getItem(cK);if(!e){return}e=JSON.parse(e);if(Date.now()>e.e){stT.removeItem(cK);return}return e.s}catch(e){return}},init:function(){if(d.URL.indexOf('__vwo_disable__')>-1)return;
  var e=this.settings_tolerance();w._vwo_settings_timer=setTimeout(function(){_vwo_code.finish();stT.removeItem(cK)},e);
  var t;if(this.hide_element()!=='body'){t=d.createElement('style');var n=this.hide_element(),i=n?n+this.hide_element_style():'',
  r=d.getElementsByTagName('head')[0];t.setAttribute('id','_vis_opt_path_hides');v&&t.setAttribute('nonce',v.nonce);t.setAttribute('type','text/css');if(t.styleSheet)t.styleSheet.cssText=i;
  else t.appendChild(d.createTextNode(i));
  r.appendChild(t)}else{t=d.getElementsByTagName('head')[0];var i=d.createElement('div');
  i.style.cssText='z-index: 2147483647!important;position: fixed !important;left: 0 !important;top: 0!important;width: 100% !important;height: 100% !important;background: white!important;';i.setAttribute('id','_vis_opt_path_hides');
  i.classList.add('_vis_hide_layer');t.parentNode.insertBefore(i,t.nextSibling)}
  var o=window._vis_opt_url||d.URL,s='https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(o)+'&vn='+version;if(w.location.search.indexOf('_vwo_xhr')!==-1){this.addScript({src:s})}else{this.load(s+'&x=true')}}};
  w._vwo_code=code;code.init();})();
  `
  const vwoScript = document.createElement('script');
  vwoScript.type = "text/javascript";
  vwoScript.id ="vwoCode";
  vwoScript.innerHTML = vwoInnerHtml
  window.document.head.appendChild(linkElement);
  window.document.head.appendChild(vwoScript);



  const first = window.document.head.firstChild
  const googleAnalyFunctionTag = document.createElement("script");
  const googleAnalyFunction = "(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date; h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')}; (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c ;})(window,document.documentElement,'async-hide','dataLayer',4000,{'OPT-M2MN69G':true});";
  googleAnalyFunctionTag.append(googleAnalyFunction);
  const googleAnalyStyleTag = document.createElement("style");
  const googleAnalyStyle = ".async-hide { opacity: 0 !important} ";
  googleAnalyStyleTag.append(googleAnalyStyle);
  window.document.head.appendChild(googleAnalyStyleTag);
  window.document.head.appendChild(googleAnalyFunctionTag);
  window.document.head.insertBefore(googleAnalyFunctionTag, first)
  window.document.head.insertBefore(googleAnalyStyleTag, first)

  const para = document.createElement("script");
  // eslint-disable-next-line no-undef
  const att = document.createAttribute("src");
  att.value = "https://www.googleoptimize.com/optimize.js?id=OPT-M2MN69G"
  para.setAttributeNode(att)
  // eslint-disable-next-line no-undef
  // eslint-disable-next-line no-undef
  window.document.head.appendChild(para)
  // eslint-disable-next-line no-undef
  window.document.head.insertBefore(para, first)

  /* SIS-734 issue(2) */
  const messagerScript = document.createElement("script");
  const messengerAtt = document.createAttribute("src");
  messengerAtt.value = "https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1";
  messagerScript.setAttributeNode(messengerAtt);
  window.document.head.appendChild(messagerScript);

  smoothscroll.polyfill();
}